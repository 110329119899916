import { render, staticRenderFns } from "./production-card.vue?vue&type=template&id=64c06d65&scoped=true&"
import script from "./production-card.vue?vue&type=script&lang=js&"
export * from "./production-card.vue?vue&type=script&lang=js&"
import style0 from "./production-card.vue?vue&type=style&index=0&id=64c06d65&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "64c06d65",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/stage-ext-new/front/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('64c06d65')) {
      api.createRecord('64c06d65', component.options)
    } else {
      api.reload('64c06d65', component.options)
    }
    module.hot.accept("./production-card.vue?vue&type=template&id=64c06d65&scoped=true&", function () {
      api.rerender('64c06d65', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/cabinets/provider/productions/production-card.vue"
export default component.exports